<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00b25a">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#d1eddf"/>
				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#00b25a"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#00b25a"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">For Teachers</p>
				<p class="subtitle-small">Encore Smart Teaching will take you and your students to a whole new level of expertise.</p>
            </div>
		</div>
		<PhotoRow :set="Math.floor(Math.random() * 3) + 4 " style="margin-top: -80px"></PhotoRow>

		<div id="content">
			<div class="content-wide">
				<div class="resources-container">
					<p class="heading-2">Online Teacher Resources</p>
					<p class="info">We have the keys to smarter teaching and faster learning. Explore our online resources including training videos, free downloads, comprehensive course overviews and more.</p>
					
					<div class="button-row">
						<button class="button-round" @click="$router.push({ name: 'videos' })">Video Library</button>
						<button class="button-round" @click="$router.push({ name: 'freeDownloads' })">Free Downloads</button>
						<button class="button-round" @click="$router.push({ name: 'teachingNotes' })">Teaching Notes</button>
						<button class="button-round" @click="$router.push({ name: 'marketingMaterials' })">Marketing Materials</button>
					</div>
				</div>
			</div>

			<div class="teacher-home-video">
				<div style="padding:56.21% 0 0 0;position:relative;" class="video-container">
					<iframe src="https://player.vimeo.com/video/720500220?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Encore Methodology"></iframe>
				</div>
				
				<p class="info-small">
					Mark Gibson talks about the philosophy and methodology of Encore Music Education. <br><span class="credit">Video is supplied courtesy of NAMM.</span>
				</p>
			</div>
		</div>

		<!-- <div class="teacher-nav-row">
			<div class="teacher-nav-button">Teacher Guides</div>
			<div class="teacher-nav-button">Teacher Training</div>
			<div class="teacher-nav-button">Licensing</div>
		</div> -->

		<div class="teacher-banner" id="teacher-banner">
			<img src="@/assets/images/encore-book-icon.svg">
			<!-- <p>{{["Teacher Guides", "Teacher Training", "Licensing", "Free Downloads"][pageIndex]}}</p> -->
			<p :class="{'selected': pageIndex == 0}" @click="$router.push('/for-teachers/0')">Teacher Guides</p>
			<p :class="{'selected': pageIndex == 1}" @click="$router.push('/for-teachers/1')">Teacher Training</p>
			<p :class="{'selected': pageIndex == 2}" @click="$router.push('/for-teachers/2')">Licensing</p>
		</div>

		<!-- TEACHER GUIDES -->

		<div id="content" class="home-content" v-if="pageIndex == 0">
			<div class="content-wide">
				<p class="course-info-preview">Preview the books</p>
				<div class="column-container books-container">
                    <div v-for="(book, j) in courses['tg'].previews" :key="j" class="book-column column-4">
                        <CourseImage :filename="book.coverFile"></CourseImage>

                        <div>
                            <p class="book-title">{{ book.title }}</p>
                            <p class="book-preview" @click="openModal(track)" v-for="(track, i) in book.tracks" :key="i"><span>play_circle </span>{{ track.name }}</p>
                        </div>
                    </div>
                </div>

				<div class="teacher-coloured-container">
					<p class="course-section-heading">Key Elements</p>
					<ul>
						<li class="course-info"><span>Detailed Curriculum</span> – outlining the core music curriculum and key learning outcomes</li>
						<li class="course-info"><span>Teaching Sequence</span> – in-depth teaching points for each piece plus a detailed teaching sequence</li>
						<li class="course-info"><span>Lesson Plans</span> – 20/25 comprehensive lesson plans in each Teacher Guide outlining the lesson structure for each week</li>
						<li class="course-info"><span>Body Percussion &amp; Movement</span> – detailed instructions for body percussion, movement activities and gestures</li>
						<li class="course-info"><span>Flash Cards</span> – Flash Card activities are integrated into lessons to develop rhythm, reading and musical understanding</li>
						<li class="course-info"><span>Extension Activities</span> – included as optional challenges to broaden the learning experience</li>
						<li class="course-info"><span>By-Ear Playing</span> – scored examples of aural training exercises to use in lessons</li>
					</ul>
				</div>

				<p class="info-bold">
					Teacher Guides are available for the following courses:
				</p>

				<p class="info">
					<ul>
						<li>Kinder Beat - Music Box Magic</li>
						<li>Kinder Beat - Percussion Play</li>
						<li>Kinder Beat - Piano Prep</li>
						<li>Encore on Keys - Junior Piano 1</li>
						<li>Encore on Keys - Junior Piano 2</li>
						<li>Encore on Keys - Primary Piano 1</li>
						<li>Encore on Keys - Primary Piano 2</li>
					</ul>
				</p>

				<button class="button-round" @click="$router.push({ name: 'order' })">Order today!</button>

				<br>
				<br>
				<p class="info-bold">
					Our Teacher Guides are professional resources written specifically for teachers. They have been carefully developed over many years of experience and detail the Encore curriculum, methodology and teaching process for each student book.
				</p>

				<p class="info">
					The Encore methodolgy and teaching process is only available through the Encore Teacher Guides and Encore Teacher Training.
				</p>

				<p class="info">
					Each Teacher Guide includes comprehensive lesson plans that show how to integrate every aspect of the Encore SMART Teaching. They have proven to be an invaluable resource for teachers who are new to Encore as well as those who have many years of experience. The Student Books provide limited teacher guidance because they feature an uncluttered layout that appeals to students of all ages.
				</p>
			</div>
		</div>

		<!-- TEACHER TRAINING -->

		<div id="content" class="home-content" v-if="pageIndex == 1">
			<div class="content-wide">
				<p class="heading-2">Online Teacher Training</p>

				<p class="info">Teacher training is now available online through our video library! <span class="info-bold">Please <router-link :to="{ name: 'contact', params: { reason: reason.teacherTraining, scroll: true }}">contact us</router-link> to set up an account.</span> If you have an account, click the button below to access the videos.</p>

				<button class="button-round" @click="$router.push({ name: 'resources' })">Video Library</button>

				<div class="teacher-coloured-container">
					<p class="course-section-heading">Key Elements</p>
					<ul>
						<li class="course-info"><span>Whole pattern learning</span></li>
						<li class="course-info"><span>Approaches to rhythm, time and space</span></li>
						<li class="course-info"><span>Integrating body percussion, gestures, props and lyrics</span></li>
						<li class="course-info"><span>Exploring visual, auditory and kinaesthetic experiences</span></li>
						<li class="course-info"><span>Modelling and layering</span></li>
						<li class="course-info"><span>Fostering creativity and imagination</span></li>
						<li class="course-info"><span>Keyboard harmony and improvisation</span></li>
						<li class="course-info"><span>Extended learning activities</span></li>
						<li class="course-info"><span>Technical foundations and skill sequencing</span></li>
						<li class="course-info"><span>Aural training and development</span></li>
						<li class="course-info"><span>Ensemble playing and associated skills</span></li>
						<li class="course-info"><span>Teaching and learning in a musical environment</span></li>
						<li class="course-info"><span>Classroom and parent management</span></li>
					</ul>
				</div>

				<p class="info-bold">
					The Encore Teacher Training seminars are some of the most comprehensive professional development sessions available to music teachers.
				</p>
				
				<p class="info">
					In our Teacher Training, we share over 35 years of teaching experience and demonstrate exactly how the Encore teaching approach should be used in lessons. The training includes the essential teaching sequence for pieces, movement activities for songs, body percussion, instrumental technique, extension activities, improvisation and classroom management skills.
				</p>

				<p class="info">
					Encore Teacher Training provides teachers with templates and demonstrations of key elements rather than a step-by-step approach for every piece in the student books. The Teacher Guides are a companion resource designed to deliver that extra level of detail. 
				</p>

				<p class="info">
					After completing Encore Teaching Training, teachers discover the true essence of Encore and what sets it apart from other method books.
				</p>

				<p class="info">
					We offer both online and in-person training sessions for individuals and Music Schools. Our experienced trainers are based in Australia, Indonesia, Malaysia and Singapore.
				</p>

				<p class="info-bold">
					Teacher Training is one of the key requirements to becoming a Licensed Encore Teacher.
				</p>

				<button class="button-round" @click="$router.push({ name: 'contact', params: { reason: reason.teacherTraining, scroll: true }})">Enquire today!</button>
			</div>
		</div>

		<!-- Licensing -->

		<div id="content" class="home-content" v-if="pageIndex == 2">
			<div class="content-wide">
				<p class="info-bold">
					The main purpose of an Encore Music Education Licence is to provide teacher certification.
				</p>

				<p class="info">
					To become a Licensed Encore Teacher, teachers must complete a minimum level of Encore Teacher Training. This ensures that teachers are skilled in the Encore methodology and SMART Teaching System. All Encore teachers are required to uphold the copyright obligations of the program.
				</p>

				<div class="licence-container kb">
					<p class="licence-heading highlight">Kinder Beat Licence</p>

					<div class="badge-row">
						<img src="@/assets/images/badges/MBM.png">
						<img src="@/assets/images/badges/PP.png">
						<img src="@/assets/images/badges/KBP.png">
					</div>
					
					<p class="info-bold">
						Kinder Beat is a Licensed Program and only available to Licensed Teachers.
					</p>
					
					<p class="info">
						Kinder Beat may only be taught by teachers who have completed Kinder Beat Teacher Training, signed the Licence Agreement and who continue to uphold the Licence Requirements. This is to ensure that all Kinder Beat teachers are trained in the curriculum and deliver the program to the highest possible standard.
					</p>

					<p class="heading-3 highlight">
						Licensing Requirements Overview
					</p>

					<ul>
						<li class="info nomargin">Teacher Training</li>
						<li class="info nomargin">Licence Application</li>
						<li class="info nomargin">Annual Licence Fee</li>
						<li class="info nomargin">Adhere to copyright laws and ensure that each student enrolled in lessons purchases a set of Student Materials.</li>
					</ul>

					<br>

					<p class="heading-3 highlight">
						Licensing Inclusions
					</p>

					<ul>
						<li class="info nomargin">Special Licensee discounts on materials</li>
						<li class="info nomargin">Ongoing access to online training videos</li>
						<li class="info nomargin">Kinder Beat promotional materials</li>
						<li class="info nomargin">Embossed Teacher/Licence Certificate</li>
						<li class="info nomargin">Website Teacher Listing</li>
						<li class="info nomargin">Encore Email Signature</li>
					</ul>

					<br>

					<p class="info">
						Licensing options are available for individual teachers and Music Schools.
					</p>

					<button class="button-round" @click="$router.push({ name: 'contact', params: { reason: reason.kinderBeatLicence, scroll: true }})">Enquire today!</button>
				</div>

				<div class="licence-container eok">
					<p class="licence-heading highlight">Encore on Keys Licence</p>

					<div class="badge-row">
						<img src="@/assets/images/badges/JR.png">
						<img src="@/assets/images/badges/PR.png">
						<img src="@/assets/images/badges/ACC.png">
						<img src="@/assets/images/badges/ACH.png">
						<img src="@/assets/images/badges/TW.png">
					</div>

					<p class="info-bold">
						Licensing is optional for this program.
					</p>

					<p class="heading-3 highlight">
						Licensing Requirements Overview
					</p>

					<ul>
						<li class="info nomargin">Teacher Training</li>
						<li class="info nomargin">Licence Application</li>
						<li class="info nomargin">Annual Licence Fee</li>
						<li class="info nomargin">Adhere to copyright laws and ensure that each student enrolled in lessons purchases a set of Student Materials.</li>
					</ul>

					<br>

					<p class="heading-3 highlight">
						Licensing Inclusions
					</p>

					<ul>
						<li class="info nomargin">Special Licensee discounts on materials</li>
						<li class="info nomargin">Ongoing access to online training videos</li>
						<li class="info nomargin">Embossed Teacher/Licence Certificate</li>
						<li class="info nomargin">Website Teacher Listing</li>
						<li class="info nomargin">Encore on Keys promotional materials</li>
						<li class="info nomargin">Encore Email Signature</li>
					</ul>

					<br>

					<p class="info">
						Licensing options are available for individual teachers and Music Schools.
					</p>

					<button class="button-round" @click="$router.push({ name: 'contact', params: { reason: reason.encoreLicence, scroll: true }})">Enquire today!</button>
				</div>
			</div>
		</div>

		<!-- Free Downloads -->

		<div id="content" class="home-content" v-if="pageIndex == 3">
			<div class="content-wide">
				<p class="info">We're helping you to create Smart Musicians! Enjoy the following resources for free.</p>	

				<div class="downloads-container">
					<div class="download-row">
						<p class="download-section-heading">Theory Worksheets</p>
						<button class="download-name" @click="downloadFile('Rhythm_Reading.pdf')">Rhythm Reading</button>
						<button class="download-name" @click="downloadFile('Treble_Staff_Note_Reading.pdf')">Treble Staff Note Reading</button>
						<button class="download-name" @click="downloadFile('Bass_Staff_Note_Reading.pdf')">Bass Staff Note Reading</button>
					</div>
					
					<div class="download-row">
						<p class="download-section-heading">Sight Reading For Piano</p>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Preliminary.pdf')">Sight Reading – Preliminary</button>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Grade_1.pdf')">Sight Reading – Grade 1</button>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Grade_2.pdf')">Sight Reading – Grade 2</button>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Grade_3.pdf')">Sight Reading – Grade 3</button>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Grade_4.pdf')">Sight Reading – Grade 4</button>
						<button class="download-name" @click="downloadFile('Encore_Sight_Reading_Grade_5.pdf')">Sight Reading – Grade 5</button>
					</div>

					<div class="download-row">
						<p class="download-section-heading">Encore Manuscript</p>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_PT_Reg.pdf')">A4 Manuscript Portrait Regular</button>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_PT_Med.pdf')">A4 Manuscript Portrait Medium</button>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_PT_Lge.pdf')">A4 Manuscript Portrait Large</button>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_LS_Reg.pdf')">A4 Manuscript Landscape Regular</button>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_LS_Med.pdf')">A4 Manuscript Landscape Medium</button>
						<button class="download-name" @click="downloadFile('Encore_A4_Manuscript_LS_Lge.pdf')">A4 Manuscript Landscape Large</button>
					</div>
				</div>
			</div>
		</div>

		<Modal v-if="showModal" @close="closeModal">
            <div class="modal-preview-container">
                <p class="preview-title">{{ modalTrack.name }}</p>
                <audio controls v-if="modalFile !== null">
                    <source :src="modalFile" type="audio/mpeg">
                    Your browser does not support the audio tag.
                </audio>
				<div class="pdfContainer">
					<pdf class="pdfViewer" :src="modalPdfFile"></pdf>
				</div>
            </div>
        </Modal>
	</div>
</template>

<script>
import Modal from '../components/Modal.vue';
import PhotoRow from '@/components/PhotoRow.vue';
import ContactReasons from '@/contact.js';
import { storage } from '@/firebase'
import pdf from 'vue-pdf';
import CourseImage from '@/components/CourseImage.vue';

import courses from '@/courses';

export default {
	props: [
		'section'
	],
	components: {
		PhotoRow,
		Modal,
        pdf,
		CourseImage
	},
	data() {
		return {
			pageIndex: 0,
			modalTrack: null,
            modalFile: null,
            modalPdfFile: null,
			showModal: false,
			courses
		}
	},
	methods: {
		changePage(index) {
			this.pageIndex = index;

			this.$scrollTo("#teacher-banner", { offset: -140 });
		},
		downloadFile(file) {
			const storageRef = storage.ref(`downloads/${file}`);
			storageRef.getDownloadURL().then(url => {
				window.open(url, "_self");
			}).catch(err => {
				console.error(err);
			})
		},
		openModal(track) {
            this.showModal = true;
            this.modalTrack = track;

            this.previewFile(track.file, track.pdfFile);

            document.body.className = "noscroll";
        },
        closeModal() {
            this.showModal = false;
            this.modalTrack = null;
            this.modalFile = null;

            document.body.className = "";
        },
        previewFile(trackFile, pdfFile) {
            const trackStorageRef = storage.ref(`samples/${trackFile}.mp3`);
            trackStorageRef.getDownloadURL().then(url => {
                this.modalFile = url;
            });

            const pdfStorageRef = storage.ref(`samples/${pdfFile}.pdf`);
            pdfStorageRef.getDownloadURL().then(url => {
                this.modalPdfFile = url;
            });
        }
	},
	computed: {
		reason() {
			return ContactReasons;
		}
	},
	mounted() {
		if (this.section) {
			this.changePage(this.section);
		}
	},
	watch: {
		section: function(val) {
			if (val == undefined) {
                this.pageIndex = 0;
            } else {
				this.changePage(val);
			}
		}
	}
}
</script>
